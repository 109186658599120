<template>
	<div class="container">
		<div class="page-sub-box ewm">
			<div class="locker_setting_list sub_new_style01 sub_ui_box1">
				<DxValidationGroup ref="searchValidationGroup">
					<div class="page_search_box line_bottom_1px">
						<div class="flex space-x-2 inner">
							<div class="flex space-x-2">
								<div class="mt-2">기준년월</div>
								<DxDateBox
									:edit-enabled="false"
									:styling-mode="config.stylingMode"
									width="100"
									v-model="searchType.customTypes.dayStart"
									type="date"
									display-format="yyyy.MM"
									dateSerializationFormat="yyyyMMdd"
									dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
									:max="searchType.customTypes.dayEnd"
									maxLength="7"
									invalid-date-message="입력 데이터가 유효하지 않습니다."
									@value-changed="onDayStartChanged"
									:calendar-options="{
										zoomLevel: 'year',
										minZoomLevel: 'year',
										maxZoomLevel: 'year',
									}"
								>
									<DxValidator>
										<DxRequiredRule message="기준년월은 필수입니다." />
									</DxValidator>
								</DxDateBox>
								<div class="mt-1">
									~
								</div>
								<DxDateBox
									:styling-mode="config.stylingMode"
									width="100"
									v-model="searchType.customTypes.dayEnd"
									type="date"
									display-format="yyyy.MM"
									dateSerializationFormat="yyyyMMdd"
									dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
									maxLength="7"
									:min="searchType.customTypes.dayStart"
									invalid-date-message="입력 데이터가 유효하지 않습니다."
									@value-changed="onDayEndChanged"
									:calendar-options="{
										zoomLevel: 'year',
										minZoomLevel: 'year',
										maxZoomLevel: 'year',
									}"
								>
									<DxValidator>
										<DxRequiredRule message="기준년월은 필수입니다." />
									</DxValidator>
								</DxDateBox>
							</div>

							<div class="mt-2">시험계획</div>
							<div>
								<DxSelectBox
									placeholder="시험계획 선택"
									:items="scheList"
									display-expr="scheNm"
									value-expr="scheId"
									:styling-mode="config.stylingMode"
									:width="200"
									:height="30"
									@value-changed="onAddSheetName"
								>
									<DxValidator>
										<DxRequiredRule message="시험계획은 필수입니다." />
									</DxValidator>
								</DxSelectBox>
							</div>
							<div class="mt-2">시험지명</div>
							<div>
								<DxTextBox
									:styling-mode="config.textStylingMode"
									:value="searchType.textboxTypes.examTypeNm"
									:width="120"
									:height="30"
									:read-only="true"
								>
								</DxTextBox>
							</div>
							<div>
								<DxTextBox
									:styling-mode="config.textStylingMode"
									:value="searchType.textboxTypes.examNm"
									:width="170"
									:height="30"
									:read-only="true"
								>
								</DxTextBox>
							</div>
							<DxButton
								text="검색"
								class="btn_M box-btn-search"
								type="button"
								:width="70"
								:height="30"
								@click="selectDataList"
							/>
						</div>
					</div>

					<div class="filter">
						<div class="filter-container">
							<div class="item">상세 필터</div>
							<div class="space-x-2">
								<DxTagBox
									ref="tagBox"
									placeholder=""
									:show-selection-controls="false"
									:search-enabled="false"
									:data-source="tagBoxData"
									:value="selectedCondition"
									display-expr="name"
									value-expr="id"
									:multiline="false"
									height="30"
									width="720"
									@value-changed="onTagValueChanged"
									:styling-mode="config.stylingMode"
								>
								</DxTagBox>
								<DxButton class="btn_XS white outlined add2" text="선택" type="button" :height="30" @click="onOpen" />
								<DxButton
									class="btn_XS white outlined del1"
									text="전체삭제"
									type="button"
									:height="30"
									@click="deleteConditions"
								/>
							</div>
						</div>
					</div>
				</DxValidationGroup>
			</div>
			<!-- Top Layer -->

			<div class="w-1/2 mt-6">
				<DxDataGrid
					class="grid-box"
					ref="groupReport"
					:allow-column-reordering="true"
					:data-source="groupReport.dataSource"
					:allow-column-resizing="true"
					:column-resizing-mode="'nextColumn'"
					:show-borders="false"
					:show-column-headers="true"
					:show-column-lines="true"
					:show-row-lines="true"
					:row-alternation-enabled="false"
					:hover-state-enabled="true"
					:word-wrap-enabled="true"
					:no-data-text="this.$_msgContents('CMN_NO_DATA')"
					:selected-row-keys="groupReport.selectedRowKeys"
					width="calc(60vh-200px)"
				>
					<DxFilterRow :visible="true" />
					<DxHeaderFilter :visible="true" />

					<DxColumn
						caption="센터"
						data-field="pathNmFull"
						alignment="center"
						:allow-sorting="false"
						:allowHeaderFiltering="true"
						:allowFiltering="true"
					>
					</DxColumn>
					<DxColumn
						caption="평균점수"
						data-field="avgScore"
						alignment="center"
						:allow-sorting="false"
						:allowHeaderFiltering="true"
						:allowFiltering="true"
					>
					</DxColumn>
					<DxColumn
						caption="순위"
						data-field="deptRanking"
						alignment="center"
						:allow-sorting="false"
						:allowHeaderFiltering="true"
						:allowFiltering="true"
						:calculate-display-value="ranking"
					/>
				</DxDataGrid>
			</div>
		</div>
		<!-- Main Layer -->

		<!-- Modal Layer -->
		<DxPopup
			:show-title="true"
			:title="modal.initData ? modal.initData.title : null"
			:min-width="modal.initData ? modal.initData.width : null"
			:width="modal.initData ? modal.initData.width : null"
			:min-height="modal.initData ? modal.initData.height : null"
			:height="modal.initData ? modal.initData.height : null"
			:drag-enabled="true"
			:resize-enabled="true"
			:show-close-button="true"
			:close-on-outside-click="false"
			v-model="modal.isOpened"
			:visible="modal.isOpened"
			@hiding="isOpenModal(false)"
		>
			<template #content>
				<div>
					<component :is="modal.currentComponent" :contentData="modal.contentData" v-model="modal.contentData"></component>
				</div>
			</template>
			<DxToolbarItem
				widget="dxButton"
				toolbar="bottom"
				location="center"
				:visible="
					modal.initData.hasOwnProperty('buttons')
						? modal.initData.buttons.hasOwnProperty('save')
							? modal.initData.buttons.hasOwnProperty('save')
							: false
						: false
				"
				:options="{
					elementAttr: {
						class: 'default filled txt_S medium',
					},
					text: modal.initData.hasOwnProperty('buttons')
						? modal.initData.buttons.hasOwnProperty('save')
							? modal.initData.buttons.save.text
							: ''
						: '',
					width: '120',
					height: '40',
					onClick: () => {
						onConfirmModal();
					},
				}"
			/>
			<DxToolbarItem
				widget="dxButton"
				toolbar="bottom"
				location="center"
				:visible="
					modal.initData.hasOwnProperty('buttons')
						? modal.initData.buttons.hasOwnProperty('cancel')
							? modal.initData.buttons.hasOwnProperty('cancel')
							: !modal.initData.buttons.hasOwnProperty('cancel')
						: false
				"
				:options="{
					elementAttr: {
						class: 'white filled txt_S medium',
					},
					text: modal.initData.hasOwnProperty('buttons')
						? modal.initData.buttons.hasOwnProperty('cancel')
							? modal.initData.buttons.cancel.text
							: ''
						: '',
					width: '120',
					height: '40',
					onClick: () => {
						isOpenModal(false);
					},
				}"
			/>
		</DxPopup>
		<!-- /Modal Layer -->
	</div>
</template>

<script>
import { DxDateBox } from 'devextreme-vue/date-box';
import { DxButton } from 'devextreme-vue/button';
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxRadioGroup } from 'devextreme-vue/radio-group';
import { DxTagBox } from 'devextreme-vue/tag-box';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import { DxDataGrid, DxColumn, DxSelection, DxFilterRow, DxHeaderFilter, DxLookup, DxLoadPanel } from 'devextreme-vue/data-grid';
import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
import { DxValidationGroup } from 'devextreme-vue/validation-group';
import { getPastFromToday, isSuccess } from '@/plugins/common-lib';
import modalSelectTargetGroup from '@/pages/ewm/exam/exam-report/group-report/modal-select-target-group.vue';

let vm = this;

export default {
	components: {
		DxDateBox,
		DxButton,
		DxTextBox,
		DxSelectBox,
		DxRadioGroup,
		DxTagBox,

		DxPopup,
		DxToolbarItem,

		DxDataGrid,
		DxColumn,
		DxSelection,
		DxFilterRow,
		DxHeaderFilter,
		DxLookup,
		DxLoadPanel,

		DxRequiredRule,
		DxValidator,
		DxValidationGroup,

		modalSelectTargetGroup,
	},
	props: {},
	watch: {
		'modal.contentData'(val) {
			this.searchType.depts = val.map(i => i.codeId);
		},
		tagBoxData(val) {
			const tagBoxDataSource = val;
			// 부서
			const selectedDeptIds = tagBoxDataSource.filter(tag => tag.type === 'dept').map(tag => tag.id);

			if (selectedDeptIds && selectedDeptIds.length > 0) {
				const selectedDept = this.modal?.contentData.filter(item => selectedDeptIds.includes(item.codeId));

				this.$set(this.modal, 'contentData', selectedDept);
			} else {
				this.$set(this.modal, 'contentData', []);
			}
		},
	},
	data() {
		return {
			filterRadioOption: {
				items: ['부서'],
			},
			selectedCondition: [],
			tagBoxData: [],
			codes: {
				examSheetType: {
					dataSource: [],
					displayExpr: 'label',
					valueExpr: 'value',
				},
			},
			config: {
				textStylingMode: 'filled',
				stylingMode: 'outlined',
			},
			modal: {
				isOpened: false,
				initData: {},
				contentData: null,
				componentName: '',
			},
			searchType: {
				obj: {},
				defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
				defaultValue: 'ROOT',
				customTypes: {
					dayStart: getPastFromToday(11, 'months'),
					dayEnd: getPastFromToday(0, 'days'),
				},
				textboxTypes: {},
				depts: [],
				paramsData: null,
			},
			scheList: [], //시험계획리스트
			groupReport: {
				dataSource: null,
				selectedRowsData: [],
				selectedRowKeys: [],
			},
		};
	},
	computed: {
		searchValidationGroup: function() {
			return this.$refs['searchValidationGroup'].instance;
		},
	},
	methods: {
		ranking(rowData) {
			const { deptRanking, totalRanking } = rowData;
			if (deptRanking) {
				return `${deptRanking}/${totalRanking}`;
			}
			return '';
		},
		onTagValueChanged(e) {
			// 선택된 조건들
			const selectedConditions = e.value;

			// 선택되지 않는 조건 삭제
			const unSelectedConditions = vm.tagBoxData.filter(item => !selectedConditions.includes(item.id));

			unSelectedConditions.some(uitem => {
				const index = vm.tagBoxData.indexOf(uitem);
				if (index > -1) {
					vm.tagBoxData.splice(index, 1);
				}
			});
		},
		onOpenModal(componentNm, componentInitData, data) {
			this.modal.currentComponent = componentNm;
			this.modal.initData = componentInitData;
			this.modal.contentData = data;
			this.isOpenModal(true);
		},
		/** @description: 팝업 오픈 체크 메서드 */
		isOpenModal(data) {
			this.modal.isOpened = data;
			if (!data) {
				this.modal.currentComponent = null;
				this.modal.initData = {};
			}
		},
		onOpen() {
			this.onOpenModal(
				'modalSelectTargetGroup',
				{
					title: '부서 선택',
					buttons: {
						save: { text: '선택' },
						cancel: { text: '닫기' },
					},
					width: '500',
					height: '800',
				},
				this.searchType.depts,
			);
		},
		deleteConditions() {
			vm.deleteTagboxes()
				.then(() => {
					this.$set(this, 'tagBoxData', vm.tagBoxData);
					this.$set(this, 'selectedCondition', []);
					this.$set(this.searchType, 'depts', []);
				})
				.then(() => {
					vm.selectDataList();
				});
		},
		async deleteTagboxes() {
			vm.tagBoxData.splice(0, vm.tagBoxData.length);
		},
		onConfirmModal() {
			const deptCodeList = this.modal.contentData;
			this.removeUnSelectedDept(deptCodeList);
			this.addDeptInTagBox(deptCodeList);

			if (!this.modal.contentData.length) {
				this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
				return;
			}
			// let obejctArray = [];
			// this.modal.contentData.forEach(i => {
			// 	obejctArray.push(i.codeId);
			// });
			// this.searchType.depts = obejctArray;
			this.isOpenModal(false);
		},
		addDeptInTagBox(selectedCode) {
			selectedCode.some(code => {
				const isExist = vm.tagBoxData.map(t => t.id).includes(code.codeId);
				if (!isExist) {
					vm.tagBoxData.push({
						id: code.codeId,
						name: code.codeNm,
						type: 'dept',
					});
				}
			});
			this.updateTagBox();
		},
		updateTagBox() {
			this.$set(this, 'tagBoxData', vm.tagBoxData);
			const selectedConditions = vm.tagBoxData.map(data => data.id);
			this.$set(this, 'selectedCondition', selectedConditions);
		},
		/** 선택되지 않는 부서 삭제 */
		removeUnSelectedDept(selectedDept) {
			const selectedConditions = selectedDept.map(data => data.codeId);
			const unselectedItems = vm.tagBoxData
				.filter(item => item.type === 'dept')
				.filter(item => !selectedConditions.includes(item.id));

			unselectedItems.some(uitem => {
				const index = vm.tagBoxData.indexOf(uitem);
				if (index > -1) {
					vm.tagBoxData.splice(index, 1);
				}
			});
		},
		/** @description : 그리드 데이터 검색 메서드 */
		async selectDataList() {
			if (!vm.searchValidationGroup.validate().isValid) {
				return;
			}
			const payload = {
				actionname: 'EWM_EXAM_GROUP_REPORT_CARD',
				data: {
					scheId: this.searchType.textboxTypes.scheId,
					deptCds: this.searchType.depts,
				},
				loading: false,
				useErrorPopup: true,
			};
			console.log(payload);
			const res = await vm.CALL_EWM_API(payload);
			if (isSuccess(res)) {
				this.groupReport.dataSource = res.data.data;
				if (this.groupReport.dataSource.length > 0) {
					const scores = this.groupReport.dataSource.map(item => {
						return item.avgScore;
					});

					this.groupReport.dataSource.push({
						pathNmFull: '전체평균점수',
						avgScore: vm.calculateAverage(scores),
					});
				}
			} else {
				this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
			}
		},
		calculateAverage(numbers) {
			if (numbers.length === 0) {
				return 0;
			}
			const sum = numbers.reduce((acc, curr) => acc + curr, 0);
			const average = sum / numbers.length;
			return Math.round(average * 10) / 10;
		},
		/** @description: 마감된 시험게획 조회 메서드 */
		async scheDataList() {
			const payload = {
				actionname: 'EWM_EXAM_REPORT_QUESTION_SCHEDULE_LIST',
				data: {
					startDt: this.searchType.customTypes.dayStart,
					endDt: this.searchType.customTypes.dayEnd,
					examProcess: this.$_enums.ewm.examProcess.FINISH.value, //마감계획
				},
				loading: false,
				useErrorPopup: true,
			};
			const res = await vm.CALL_EWM_API(payload);
			if (isSuccess(res)) {
				this.scheList = res.data.data;
			} else {
				this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
			}
		},
		/** @description : selectBox 선택 시 시험지명 넣어주기 */
		onAddSheetName(value) {
			const selectedSche = this.scheList.find(sche => sche.scheId === value.value);

			this.searchType.textboxTypes = selectedSche;
			if (selectedSche) {
				this.searchType.textboxTypes.examTypeNm = this.codes.examSheetType.dataSource.find(
					c => c.value === selectedSche.examSheetType,
				).label;
				this.searchType.textboxTypes.examNm = selectedSche.examNm;
			}
		},
		onDayStartChanged(e){
			const changed = e.value.slice(0, 6)+'01';
			this.searchType.customTypes.dayStart = changed;
      		this.scheDataList();
		},
		onDayEndChanged(e){
			const changed = e.value.slice(0, 6)+'29';
			this.searchType.customTypes.dayEnd = changed;
      		this.scheDataList();
		},
		/** @description : 셀렉트 박스 초기화 */
		async initCodeMap() {
			this.codeMap = await this.$_getCodeMapList('root_edu_exam_type');
		},
		/** @description : 라이프사이클 created시 호출되는 메서드 */
		createdData() {
			vm = this;

			this.initCodeMap().then(() => {
				this.codes.examSheetType.dataSource = this.$_enums.ewm.examSheetType.values; //시험타입
			});
		},
		/** @description: 라이프사이클 mounted시 호출되는 메서드 */
		mountedData() {
			this.scheDataList();
		},
	},
	created() {
		this.createdData();
	},
	mounted() {
		this.mountedData();
	},
};
</script>

<style scoped>
.border-none * {
	border: none;
	width: auto;
}
.filter {
	background-color: #f9f9f9;
}
.filter-container {
	display: flex;
	align-items: center;
	margin: 5px;
}
.item {
	padding: 10px;
	margin: 5px;
}
</style>
